.reset-card {
    transition: height 0.5s linear;
}

.reset-form {
    max-width: 300px;
}

.reset-form-forgot {
    float: right;
}

.reset-form-button {
  width: 100%;
}

.spinner {
    text-align: center;
    animation: fadeIn 0.5s;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}
