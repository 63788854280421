.SideMenu {
    color: white;
    font-size: 14px;
    min-height: 100vh;
    height: 100%;
    overflow: auto;

}

@media print {
    .SideMenu { 
        display: none;
    }
}
