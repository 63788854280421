


.error-fatal {

    background: #FF0000; 
    font-family: monospace; 
    height: 100vh;
    color: white;
    line-height: 24px;
}

.error-container {
    text-align: center;
    margin: 30vh auto 0 auto;
    max-width: 500px;
    width: 100%;
    padding: 0 1ch;
}
.error-title {
    font-weight: 600;
    margin-bottom: 24px;
    background-color: white;
    color: #FF0000;
    display: inline-block;
    max-width: auto;
    padding: 0px 1ch;

}

.error-explain {
    margin-bottom: 24px;

}
