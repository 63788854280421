.csvit {
  border: 1px solid black;
  margin-bottom: 12px;
  font-family: monospace;
  font-size: 12px;
  line-height: 14px;
}


.csvith th {
    border: 1px solid black;
    background-color: #e9eaed;
}

.csvitd td {
    border: 1px solid black;
    text-align: center;
}

.csvincludedrow {
    background-color: #fafaeb;
}

.csvexcludedrow {
    color: #b2b2b2;
}

.csvincludedcolumn {
    background-color: #FFFFC9;
}


.csvincludedcolumn_ol{
    border-left: 3px solid green !important;
    border-right: 3px solid green !important;
}

.csvincludedcolumn_end{
    border-bottom: 3px solid green !important;
}

.csvincludedcolumn_start{
    border-top: 3px solid green !important;
}
