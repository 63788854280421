.ant-form {
    max-width: 100%;
    min-width: fit-content;
}

.ant-form-item-label {
    font-weight: 600;
    text-align: left;
}


.modal-form label {
    font-size: 12px;
    padding-bottom: 0px;
}

.modal-form .ant-form-item-label {
    padding-bottom: 0px;
    line-height: 14px;
}

.modal-form .ant-form-item-control {
    margin-bottom: 8px;
}


.ant-form-item-required::after {
    display: inline-block;
    margin-right: 4px;
    content: "*";
    font-family: SimSun;
    line-height: 1;
    font-size: 14px;
    color: #f5222d;
}

.form-item {
    margin-bottom: 2px;
}

.form-item-buttons {
    margin-bottom: 14px;
}

.form-item-delete-button {
    text-align: right;
    width: 100%;
}

/* .form-item:nth-child(odd) { */
/*     background: #f4f7fc; */
/* } */

/* .ant-form-item:nth-child(odd) .ant-form-item-label { */
/*     background: #f4f7fc; */
/* } */

/* .ant-form-item:nth-child(odd) .ant-form-item-control-wrapper { */
/*     background: #f4f7fc; */
/* } */
