
.importTable {
  border: 1px solid black;
  margin-bottom: 12px;
}


.headerRow th {
    padding: 8px;
    border: 1px solid black;
    background-color: #e9eaed;
}

.productRow td {
    padding: 8px;
    border: 1px solid black;
    text-align: center;
}
