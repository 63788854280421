.backlink {
    border: 0px;
    box-shadow: 0px 0px;
    margin-bottom: 12px;
    color: gray;
    display: block;
}

@media print { 
    .backlink {
        display: none;
    }
}
