.relations-table {
    display: block;
}


.search-row {
    display: flex;
}

.relation-row {
    display: flex;
}

.pending-relation .ant-list-item-meta-title {
    font-style: italic;
    color: lightgray;
}

.removing-relation .ant-list-item-meta-title {
    font-style: italic;
    color: red;
}


.relation-label {
    font-weight: 600;
    margin-right: 12px;
    width: 100%;
}

.ant-list-item-meta-title {
    margin-bottom: 0px;
}

.ant-list-bordered.ant-list-sm .ant-list-header {
    padding: 8px 8px;
}

.ant-list-bordered.ant-list-sm .ant-list-footer {
    padding: 8px 8px;
}

.remove-link {
    flex-basis: auto;
}
