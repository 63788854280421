
.devStyle { 
    display: inline-block;
    padding: 0px 12px; 
    font-weight: 800; 
    text-shadow: 0px 1px #F7C246; 
    background-color: #EFAF27;
    color: #A88048;
}

@media print {
    .devStyle {
        display: none;
    }
}
