.action {
    width: 300px;
    font-size: 16px;
    font-weight: 500;
    box-shadow: 0px 2px 5px #8888AA33;
    border: 1px solid #e8e8e8; 
    border-radius: 2px 2px 0 0;
    padding: 24px;
    font-family: Monospaced Number,Chinese Quote,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif;
}

.actionText {
    padding: 16px 0;
    font-weight: 500;

}
