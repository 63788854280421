.report .title {
    font-family: monospace;
    display: inline-block;
    margin-bottom: 12px;
    font-weight: bold;
    text-transform: lowercase;
}

.report .empty {
    font-style: italic;
    color: #BBBBBB;
}

.report table {
    border-collapse: collapse;
}

.report th {
    background-color: #EEEEEE;
    padding: 2px 4px;
    text-transform: lowercase;
}

.report td {
    padding: 2px 4px;
}

.report table, .report td, .report th, .report tr {
    border: 1px solid #AAAAAA;
}

.report tr:hover {
    background-color: #F4FCFF;
}

@media print {

    .report th {
        font-size: 8pt;
    }

    .report table {
        font-size: 6pt;
    }

}
