



.create-controls {
    display: flex;
}


@media print {
    .create-controls {
        display: none;
    }

    .form-controls {
        display: none;
    }
}
