
.newFormTable {

    border: 1px solid black;

}


.newFormLabel {

    user-select: none;
    border: 1px solid black;
    background-color: lightgray;
    font-weight: 800;
}
