.label {
    display: inline-block;
    border-radius: 2px;
    padding: 1px 12px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    margin-right: 10px;
    white-space: nowrap;
    text-align: center;
}

