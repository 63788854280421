
.distsection {
    display: table;
    padding: 8px;
    border: 1px solid #B8B8B8;
    margin-bottom: 24px;
}

.disttable {


}

.distheader {

    line-height: 20px;
}

.distheader th {
    background-color: #E8E8E8;
}

.distbody tr {
    line-height: 20px;
}

.distsum {
    background-color: #E8E8E8;
    width: 100%;
}

.distprod {
    font-weight: bold;
    margin-right: 24px;
    font-size: 18px;
}

.diststat {
    padding: 4px;
    display: inline;
    background-color: #F8F8F8;
    width: 40px;
    border: 1px solid #B8B8B8;
    margin-right: 12px;
}

.distactionscell {
    display: inline-block;
    width: 160px;
}

.distdelete {
}

.distsave {
    margin-right: 12px;
}


.distselectCell {
    display: inline-block;
    width: 100px;
}

.distquantityCol {
    width: 50px;
}
