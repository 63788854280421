
.ant-table-small .ant-table-content .ant-table-body {
    margin: 0px;
}

td {
    vertical-align: top;
}


.collapsed-row {
    display: none;
}

.bluerow th {
    color: red !important;
    background-color: blue !important;
    border-color: blue;
}
