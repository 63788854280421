.dt_border {
  border: 1px solid black;
  line-height: 18px;
}

.dt_header {
    padding: 4px;
    background-color: #D7D7DF;
}

.dt_cell {
    padding: 2px;

}
