.new-password-card {
    transition: height 0.5s linear;
}

.new-password-form {
    max-width: 300px;
}

.new-password-form-forgot {
    float: right;
}

.new-password-form-button {
  width: 100%;
}

.spinner {
    text-align: center;
    animation: fadeIn 0.5s;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}
