.logWindow {
    max-height: 230px;
    overflow-y: scroll;
}

.logHeader {
    margin-bottom: 5px;
}

.inputBar {
    margin-bottom: 12px;
}

.loadingLine {
    background-color: #e9f1fc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 6px;
    margin-right: 6px;
}

.activityLine {
    white-space: pre-line;
    background-color: #F6F6F6; 
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 6px;
    margin-right: 6px;
}

.activityText {
    margin-bottom: 0px;
}

.emptyText {
    margin-bottom: 0px;
    font-style: italic;
    color: #A0A0A0;
}

.inputBox {
    box-sizing: border-box;
    width: 100%;
}
